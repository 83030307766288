import {
  IonApp,
  IonBadge,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
} from "@ionic/react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonItem,
  IonList,
  IonToggle,
} from "@ionic/react";

import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route, useParams, Switch } from "react-router-dom";
import Menu from "./components/Menu";
import LoginPage from "./pages/Login";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { ar_loginUser, ar_logoutUser } from "./Redux/Actions/AuthActions";
// import subt from "../public/Image/SU.png";
// import ethbt from "./Image/Eth.png";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/floating-tab-bar.css";
import { home, settings, moon } from "ionicons/icons";
import SUData from "./pages/SUData";
import ETHData from "./pages/ETHData";
import Homepage from "./pages/Homepage";
import SUPred from "./pages/SUPred";

// Toolbar img
// import beta from "../../su-sales-dashboard-ionic-ui/src/Image/BETA.png";

setupIonicReact();

const App = () => {
  const [loading, setLoading] = useState(true);
  const authReducer = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();

  // const { name } = useParams();

  const validateToken = () => {
    setLoading(true);
    try {
      if (localStorage.USERLoginTOKEN) {
        const token = localStorage.getItem("USERLoginTOKEN");
        const decoded = jwt_decode(token);
        // const currentTime = Date.now() / 1000; // to get in milliseconds
        // if (currentTime <= decoded.exp) {

        const data = {
          isAuthenticated: true,
          user: {
            userId: decoded.user_id,
            username: decoded.username,
            last_login: decoded.last_login,
            email: decoded.email,
          },
        };
        console.log("data", data, decoded);

        localStorage.setItem("userId", data.user.userId);
        localStorage.setItem("last_login", data.user.last_login);

        dispatch(ar_loginUser(data));
      }
    } catch (error) {}
    // dispatch(ar_loginUser({ isAuthenticated: true, user: {} }));  // Uncomment this line for testing
    setLoading(false);
  };

  useEffect(() => {
    validateToken();
  }, []);

  const toggleDarkModeHandler = () => document.body.classList.toggle("dark");

  return loading ? (
    <p>LoginPage</p>
  ) : (
    <IonApp>
      <IonReactRouter>
        {authReducer.isAuthenticated ? (
          <IonSplitPane contentId="main">
            <Menu />
            <IonRouterOutlet id="main">
              <IonPage>
                <IonHeader>
                  <IonToolbar>
                    <IonButtons slot="start">
                      <IonMenuButton />
                    </IonButtons>
                    <IonTitle>
                      <div
                        id="navbar-portal-div"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          // marginLeft:"100px",
                          // marginLeft: '20px',
                          alignItems: "center",
                        }}
                      >
                          {/* <IonBadge color="success">BETA</IonBadge> */}
                        </div>          
                    </IonTitle>
                  </IonToolbar>
                </IonHeader>
                <Switch>
                <Route path="/sales/:store" exact={true}>
                  <IonContent fullscreen>
                    <Route path="/sales/styleunion" exact={true}>
                      <SUData />
                    </Route>
                    <Route path="/sales/eth" exact={true}>
                      <ETHData />
                    </Route>
                    {/* <Route path="*">
                      <Redirect to="/sales/styleunion" />
                    </Route> */}
                  </IonContent>
                  <IonTabBar slot="bottom">
                    <IonTabButton tab="SU" href="/sales/styleunion">
                      <img src="SU.png" alt="subt" />
                    </IonTabButton>
                    <IonTabButton tab="ETH" href="/sales/eth">
                      <IonLabel>
                        <img src="Eth.png" alt="ethbt" />
                      </IonLabel>
                    </IonTabButton>
                  </IonTabBar>
                </Route>
                <Route path="/su-pred" exact={true}>
                    <IonContent fullscreen>
                      <SUPred />
                    </IonContent>
                  </Route>
                  <Route path="*">
                    {/* HIIII */}
                    <Redirect to="/su-pred" />
                  </Route>
                </Switch>
              </IonPage>
            </IonRouterOutlet>
          </IonSplitPane>
        ) : (
          <IonRouterOutlet id="main">
            <Route path="*">
              <LoginPage />
            </Route>
            {/* <Route path="*">
              <Redirect to="/login" />
            </Route> */}
          </IonRouterOutlet>
        )}
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
