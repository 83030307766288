import "./StoreCard.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  IonSkeletonText,
  useIonToast,
  IonButton,
  IonList,
  IonPopover,
  IonItem,
  IonRadioGroup,
  IonRadio,
  IonBadge,
  IonRefresher,
  IonRefresherContent,
  RefresherEventDetail,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import Chart from "./Chart";
import ReactApexChart from "react-apexcharts";
import { chevronDownCircleOutline } from "ionicons/icons";
import SortFunctions from "../SortFunctions";

const StoreCard1 = () => {
  const [details, setDetails] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [present] = useIonToast();
  const [searchTerm, setSearchTerm] = useState("");
  const [sortState, setSortState] = useState("default");
  const [open, setOpen] = useState(false);
  const { handleAscSort, handleDesSort } = SortFunctions({
    details,
    setSortState,
    setDetails,
  });

  const dismiss = () => {
    setOpen(false);
  };

  function handleDSort() {
    window.location.reload(true);
  }

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      // Any calls to load data go here
      event.detail.complete();
      getData();
    }, 2000);
  }

  function handleSortChange(sortOption) {
    if (sortOption === "asc") {
      handleAscSort("current_sale");
    } else if (sortOption === "desc") {
      handleDesSort("current_sale");
    }
  }

  const getData = async () => {
    setLoading(true);
    await axios
      .get("/su/prediction")
      .then((res) => {
        setDetails(res.data);
      })
      .catch((err) => {
        err.handleGlobally && err.handleGlobally("Error Occured");
        present({
          message: "Error Occured",
          duration: 2000,
          color: "danger",
        });
        console.log(err);
      });
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const isOldData = () => {
    if (details && details.length > 0) {
      const updatedAtTime = new Date(details[0]?.updated_at);
      const currentTime = new Date();
      const timeDifferenceInMilliseconds = currentTime - updatedAtTime;
      const twoHoursInMilliseconds = 2 * 60 * 60 * 1000;
      return timeDifferenceInMilliseconds > twoHoursInMilliseconds;
    }
    return false;
  };

  const series = [
    {
      name: "Today",
      data: [...details?.slice(1)].map((x) => x.current_sale),
    },
  ];

  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "14px",
        colors: ["#fff"],
      },
    },
    xaxis: {
      categories: [...details?.slice(1)].map((x) => x.site_name),
      labels: {
        style: {
          fontSize: "14px",
          colors: "white",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: "12px",
          colors: "white",
        },
      },
    },
  };

  return (
    <>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <input
          id="searchInput"
          style={{
            color: "white",
            padding: "10px",
            borderRadius: "5px",
            width: "90%",
            textAlign: "left",
            marginTop: "15px",
            background: "#1A1A1A",
          }}
          type="text"
          placeholder="Search for site"
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
        />
      </div>
      <div
        style={{
          display: "inline-flex",
          justifyContent: "space-around",
          width: "100%",
          flexWrap: "wrap",
          position: "relative",
          paddingBottom: "50px",
          background: "black",
          // marginTop:"100px"
        }}
      >
        {loading && !details?.length && (
          <>
            <IonSkeletonText
              animated={true}
              style={{ width: "min(400px, 90%)", height: "150px" }}
            ></IonSkeletonText>
            <IonSkeletonText
              animated={true}
              style={{ width: "min(400px, 90%)", height: "150px" }}
            ></IonSkeletonText>
            <IonSkeletonText
              animated={true}
              style={{ width: "min(400px, 90%)", height: "150px" }}
            ></IonSkeletonText>
            <IonSkeletonText
              animated={true}
              style={{ width: "min(400px, 90%)", height: "150px" }}
            ></IonSkeletonText>
            <IonSkeletonText
              animated={true}
              style={{ width: "min(400px, 90%)", height: "150px" }}
            ></IonSkeletonText>
            <IonSkeletonText
              animated={true}
              style={{ width: "min(400px, 90%)", height: "150px" }}
            ></IonSkeletonText>
            <IonSkeletonText
              animated={true}
              style={{ width: "min(400px, 90%)", height: "150px" }}
            ></IonSkeletonText>
            <IonSkeletonText
              animated={true}
              style={{ width: "min(400px, 90%)", height: "150px" }}
            ></IonSkeletonText>
            <IonSkeletonText
              animated={true}
              style={{ width: "min(400px, 90%)", height: "150px" }}
            ></IonSkeletonText>
            <IonSkeletonText
              animated={true}
              style={{ width: "min(400px, 90%)", height: "150px" }}
            ></IonSkeletonText>
          </>
        )}

        {details
          .filter((val) => {
            if (searchTerm == "site_name") {
              return val;
            } else if (
              val.site_name.toLowerCase().includes(searchTerm.toLowerCase())
            ) {
              return val;
            }
          })

          .map((items) => {
            return (
              <>
                <div className="codecard">
                  {/* ---------------------------CARD HEADER------------------------------------- */}
                  <label className="shopname" htmlFor="shop title">
                    {items.site_name}
                  </label>
                  <div
                    style={{
                      width: "97%",
                      display: "flex",
                      justifyContent: "right",
                    }}
                  >
                    {isOldData() && (
                      <IonBadge className="oldData" color="warning">
                        Old Data
                      </IonBadge>
                    )}
                    {/* "updated_at": "2023-07-25T16:11:25.880000", */}
                  </div>
                  <div className="vector-wrapper">
                    <table>
                      <tr>
                        <td>
                          {items.site_name == "TOTAL" ? (
                            <></>
                          ) : (
                            <>
                              {items?.weather?.main == "Thunderstorm" ? (
                                <>
                                  <iframe
                                    src="thunderstorm.gif"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      border: "none",
                                    }}
                                  ></iframe>
                                </>
                              ) : items?.weather?.main == "Drizzle" ? (
                                <>
                                  <iframe
                                    src="drizzle.gif"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      border: "none",
                                    }}
                                  ></iframe>
                                </>
                              ) : items?.weather?.main == "Rain" ? (
                                <>
                                  <iframe
                                    src="rain.gif"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      border: "none",
                                    }}
                                  ></iframe>
                                </>
                              ) : items?.weather?.main == "Snow" ? (
                                <>
                                  <iframe
                                    src="snow.gif"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      border: "none",
                                    }}
                                  ></iframe>
                                </>
                              ) : items?.weather?.main == "Mist" ? (
                                <>
                                  <iframe
                                    src="mist.gif"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      border: "none",
                                    }}
                                  ></iframe>
                                </>
                              ) : items?.weather?.main == "Smoke" ? (
                                <>
                                  <iframe
                                    src="mist.gif"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      border: "none",
                                    }}
                                  ></iframe>
                                </>
                              ) : items?.weather?.main == "Haze" ? (
                                <>
                                  <iframe
                                    src="haze.gif"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  ></iframe>
                                </>
                              ) : items?.weather?.main == "Dust" ? (
                                <>
                                  <iframe
                                    src="mist.gif"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      border: "none",
                                    }}
                                  ></iframe>
                                </>
                              ) : items?.weather?.main == "Fog" ? (
                                <>
                                  <iframe
                                    src="mist.gif"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      border: "none",
                                    }}
                                  ></iframe>
                                </>
                              ) : items?.weather?.main == "Sand" ? (
                                <>
                                  <iframe
                                    src="mist.gif"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      border: "none",
                                    }}
                                  ></iframe>
                                </>
                              ) : items?.weather?.main == "Ash" ? (
                                <>
                                  <iframe
                                    src="mist.gif"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      border: "none",
                                    }}
                                  ></iframe>
                                </>
                              ) : items?.weather?.main == "Squall" ? (
                                <>
                                  <iframe
                                    src="mist.gif"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      border: "none",
                                    }}
                                  ></iframe>
                                </>
                              ) : items?.weather?.main == "Clear" ? (
                                <>
                                  <iframe
                                    src="clear.gif"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      border: "none",
                                    }}
                                  ></iframe>
                                </>
                              ) : items?.weather?.main == "Clouds" ? (
                                <>
                                  <iframe
                                    src="clouds.gif"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      border: "none",
                                    }}
                                  ></iframe>
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                          &nbsp;
                        </td>
                        <td>
                          {items.site_name == "TOTAL" ? (
                            <>
                              <td></td>
                            </>
                          ) : (
                            <>
                              <td>| {items?.weather?.temperature}°C</td>
                            </>
                          )}
                        </td>
                      </tr>
                    </table>
                  </div>
                  {/* ------------------------------CARD HERO SECTION----------------------------------------- */}

                  <div className="maincard" />
                  <div className="mainnumbers">
                    <div className="mainnumbers1">
                      <div className="div1">{`₹ `}</div>
                      <label className="actualvalue" htmlFor="Main Sales">
                        {items.current_sale}L
                      </label>
                      <div className="div2">{`/`}</div>
                      <label className="predicted" htmlFor="Predicted Sales">
                        {items.predicted_sale || "0"}L
                      </label>
                      <label
                        className="mainpercentage"
                        htmlFor="Sales Percentage"
                      >
                        <span className="mainpercentage-txt">
                          {/* <span>{`( `}</span> */}
                          <span className="span4">
                            ({items.sale_diff_percentage}%)
                          </span>
                          {/* <span>{' )'}</span> */}
                        </span>
                      </label>
                    </div>
                  </div>
                  <label className="today1">
                    <p className="today2" style={{ color: "#70a2ff" }}>
                      Today
                    </p>
                  </label>
                  <label className="sale-predicted1">Sale / Predicted</label>
                  {/* --------------------------------------Todays Percentage----------------------------------------- */}

                  <label className="percent">{items.today_hike}%</label>
                  {items.today_hike < 0 ? (
                    <>
                      <img className="arrow-icon" alt="" src="ArrowDown.png" />
                    </>
                  ) : (
                    <>
                      <img className="arrow-icon" alt="" src="Arrow.png" />
                    </>
                  )}
                  {/* <img className="arrow-icon" alt="" src={Arrow} /> */}

                  {/* -------------------------------CARD FOOTER----------------------------------------------- */}

                  <div className="unit-frame-parent">
                    <table style={{ marginLeft: "-12px" }}>
                      <tr className="tdspan1st">
                        <td className="tdinnerspan">
                          <img alt="" src="unit.png" />
                        </td>
                        <td
                          className="tdinnerspan"
                          style={{ color: "#6ca4f8" }}
                        >
                          {/* {items.unit_qty } */}
                          {items?.unit_qty <= 999
                            ? items?.unit_qty
                            : items?.unit_qty > 999
                            ? (items?.unit_qty / 1000)?.toFixed(1) + "K"
                            : "0"}
                        </td>
                        <td className="tdinnerspan2">Units</td>

                        <td className="tdinnerspan">
                          <img alt="" src="bill.png" />
                        </td>
                        <td
                          className="tdinnerspan"
                          style={{ color: "#70a2ff" }}
                        >
                          {items.bills_count}
                        </td>
                        <td className="tdinnerspan2">Bills</td>

                        <td className="tdinnerspan">
                          <img alt="" src="visitors.png" />
                        </td>
                        <td
                          className="tdinnerspan"
                          style={{ color: "#70a2ff" }}
                        >
                          {items?.pcm_count <= 999
                            ? items?.pcm_count
                            : items?.pcm_count >= 1000
                            ? (items?.unit_qty / 1000)?.toFixed(1) + "K"
                            : "0"}
                        </td>
                        <td className="tdinnerspan2">Visitors</td>
                      </tr>
                    </table>
                  </div>
                  <div className="unit-frame1">
                    <div className="wtd">WTD</div>
                    <label className="unitvalue1" htmlFor="Unit">
                      {items.this_week_amt}L
                    </label>
                  </div>

                  <Chart details={items} storeCode={items?.store_code} />
                  <div className="codecard-child" />
                  <div className="codecard-item" />
                  <div className="codecard-inner" />
                </div>
              </>
            );
          })}

        <div class="ion-footer">
          <IonButton
            className="footerbutton"
            id="popover-top-button"
            size="large"
            style={{ width: "100%", color: "white", height: "42px" }}
          >
            Sort By
          </IonButton>
          <IonButton
            onClick={() => {
              setOpen(true);
            }}
            className="footerbutton"
            id="open-sales"
            size="large"
            style={{ width: "100%", color: "white", height: "42px" }}
          >
            All Sales
          </IonButton>
        </div>
        <div>
          <IonPopover
            keepContentsMounted={true}
            dismissOnSelect={true}
            trigger="popover-top-button"
            side="top"
            size="auto"
          >
            <IonList>
              <IonRadioGroup>
                <IonItem onClick={() => handleDSort("default")}>
                  Default
                </IonItem>
                <IonItem
                  onClick={() => {
                    handleAscSort("current_sale");
                    setSortState("asc");
                  }}
                >
                  <b>Sales</b>&nbsp;| Low to High
                  <IonRadio slot="end"></IonRadio>
                </IonItem>
                <IonItem
                  onClick={() => {
                    handleDesSort("current_sale");
                    setSortState("desc");
                  }}
                >
                  <b>Sales</b> &nbsp;| High to Low
                  <IonRadio slot="end"></IonRadio>
                </IonItem>
                <IonItem onClick={() => handleAscSort("pcm_count")}>
                  <b> Visitors</b> &nbsp;| Low to High
                  <IonRadio slot="end"></IonRadio>
                </IonItem>
                <IonItem onClick={() => handleDesSort("pcm_count")}>
                  <b>Visitors</b> &nbsp;| High to Low
                  <IonRadio slot="end"></IonRadio>
                </IonItem>
              </IonRadioGroup>
            </IonList>
          </IonPopover>
        </div>
        <div>
          <IonModal trigger="open-sales" isOpen={open}>
            <IonHeader>
              <IonToolbar>
                <IonTitle>All Sales</IonTitle>
                <IonButtons slot="end">
                  <IonLabel>Sort By:</IonLabel>
                  <IonList>
                    <IonSelect
                      interface="popover"
                      value={sortState}
                      onIonChange={(e) => {
                        handleSortChange(e.detail.value);
                      }}
                    >
                      <IonSelectOption value="asc">Ascending</IonSelectOption>
                      <IonSelectOption value="desc">Descending</IonSelectOption>
                    </IonSelect>
                  </IonList>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <div style={{ margin: "5px 20px 20px 5px" }}>
                <div id="chart">
                  <ReactApexChart
                    options={options}
                    series={series}
                    type="bar"
                    height={1000}
                  />
                </div>
              </div>
            </IonContent>
            <ion-footer>
              <p onClick={() => dismiss()} className="back">
                Back
              </p>
            </ion-footer>
          </IonModal>
        </div>
      </div>

      {/* Pull Down to Refresh ↓ */}
      <IonRefresher
        slot="fixed"
        pullFactor={0.5}
        pullMin={100}
        pullMax={150}
        onIonRefresh={handleRefresh}
      >
        <IonRefresherContent
          pullingIcon={chevronDownCircleOutline}
          refreshingSpinner="circles"
          refreshingText="Refreshing..."
        ></IonRefresherContent>
      </IonRefresher>
    </>
  );
};

export default StoreCard1;
