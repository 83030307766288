import axios from "axios";

export const sendAnalytics = (screenId, userId, username) => {
  console.log("sendAnalytics", screenId, userId, username);
  axios.post("/analytic_data", {
    screen_id: screenId,
    user_id: userId,
    username: username,
  });
};

export const analyticFrequencyInMS = 15000;
