import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import axios from "axios";
import combinedReducer from "./Redux/Reducers/CombinedReducer";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const toastProps: any = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

const store = configureStore({
  reducer: combinedReducer,
});

axios.defaults.headers.common["Authorization"] =
  localStorage.getItem("USERLoginTOKEN");


// axios.defaults.baseURL ="http://192.168.1.4:6767";
// axios.defaults.baseURL ="http://10.129.2.24:6767";
axios.defaults.baseURL ="https://ethnicity-sales-app.smart-iam.com/sales-api-v2/";
// axios.defaults.baseURL ="http://192.168.2.236:6766"

const errorComposer = (error: any, prefixMessage: any) => {
  const statusCode = error.response ? error.response.status : null;
  const m = error.response ? error.response.data.detail : null;
  const errorMessage = m ? m : error.message;

  if (!statusCode) {
      // message.error(prefixMessage + " : Network Error");
      toast.error(prefixMessage + " : Network Error", toastProps);        
      return;
  } else if (errorMessage) {
      // message.error(prefixMessage + " : " + errorMessage);
      toast.error(prefixMessage + " : " + errorMessage, toastProps);
  } else if (statusCode === 404) {
      // message.error(prefixMessage + " : Not Found");
      toast.error(prefixMessage + " : Not Found", toastProps);
  }

  if (statusCode === 401) {
      // message.error(prefixMessage + " : Unauthorized");
      toast.error(prefixMessage + " : Unauthorized", toastProps);
      localStorage.removeItem("USERLoginTOKEN");
      window.location.href = "/login";
  }
};

axios.interceptors.response.use(undefined, (error) => {
  console.log(error);
  error.handleGlobally = (prefixMessage: any) => {
      errorComposer(error, prefixMessage);
  };

  return Promise.reject(error);
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
