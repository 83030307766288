import { useState } from "react";
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonItemDivider, IonCardSubtitle, IonCardTitle } from "@ionic/react";
import './StoreCard.css';
import dayjs from "dayjs";
var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);
// export default function StoreCard() {
const styles = {
    value: {
        fontSize: "16px",
        marginBottom: "4px",
        fontWeight: "500",
        // ellipsis: true,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    label: {
        // color: "black",
        fontSize: "18px",
        fontWeight: "400",
        //   color: "grey",
        fontFamily: "Arial, Helvetica, sans-serif !important",
        // text-transform: "lowercase";
        // ellipsis: true,
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",

    },
};

/*
{"today_amt":0.0,"yesterday_amt":63.89,"this_week_amt":321.0,"this_month_amt":1255.88,"this_year_amt":12725.07,"today_qty":0.0,"yesterday_qty":12545.0,"this_week_qty":61969.0,"this_month_qty":247747.0,"this_year_qty":2216127.0,"site_name":"Total","store_name":"Style Union","org_code":"SU","updated_at":"2023-03-26 10:48:33.969115","site_code":null}
*/

export const CardComponent = ({ 
    data,
    fields=[],
    fieldsInOneRow=5,
}) => {
    return (
        // Width using media queries
        <IonCard style={{ width: "min(400px, 90%)", maxWidth: "max(400px, 90%)" ,marginBottom:"5px" }}>
            <IonCardHeader style={{ borderBottom: "1px solid grey", paddingBottom: "5px" }}>
                <IonCardTitle style={{ display: "inline-flex", justifyContent: "space-between", width: "100%"}}>
                    <span style={{fontSize: '17px' , marginBottom:"10px"}}>{data.store_name}</span>
                    {/* <span>{dayjs(data.updated_at).fromNow()}</span> */}
                </IonCardTitle>
                {/* <IonCardSubtitle>TN</IonCardSubtitle> */}
            </IonCardHeader>
            <IonCardContent style={{paddingTop: '10px'}} >
                
				{/* Add a flex container with 5 cols in a row */}
				{/* <div style={{ display: "inline-flex", justifyContent: "space-between", width: "100%", marginTop: "10px", gap: '5px' }}>
					{fields.map((field, _i) => _i < fieldsInOneRow && (
						<div key={_i} style={{flex: 1, textAlign: 'center'}}>
							<IonCardTitle style={styles.value}>{field.label}</IonCardTitle>
							<IonCardSubtitle style={styles.label}>{data[field.dataIndex]}</IonCardSubtitle>
						</div>
					))}
				</div>
				<div style={{ display: "inline-flex", justifyContent: "space-between", width: "100%", marginTop: "10px", gap: '5px' }}>
					{fields.map((field, _i) => _i >= fieldsInOneRow && (
						<div key={_i} style={{flex: 1, textAlign: 'center'}}>
							<IonCardTitle style={styles.value}>{field.label}</IonCardTitle>
							<IonCardSubtitle style={styles.label}>{data[field.dataIndex]}</IonCardSubtitle>
						</div>
					))}
				</div> */}

                {/* Make the number of div automatically from the fields array and noOfFieldsInOneRow */}
                {Array.from({ length: Math.ceil(fields.length / fieldsInOneRow) }, (_, i) => i).map((row, _i) => (
                    <div key={_i} style={{ display: "inline-flex", justifyContent: "space-between", width: "100%", marginTop: "10px", gap: '10px' }}>
                        {fields.map((field, _j) => _j >= row * fieldsInOneRow && _j < (row + 1) * fieldsInOneRow && (
                            <div key={_j} style={{ flex: 1, textAlign: 'center' }}>
                                <IonCardTitle style={styles.value}>{field.label}</IonCardTitle>
                                <IonCardSubtitle style={styles.label}>{data[field.dataIndex]}</IonCardSubtitle>
                            </div>
                        ))}
                    </div>
                ))}

			</IonCardContent>
        </IonCard>
    );
};

// return (
//     <div style={{ display: "inline-flex", justifyContent: "space-around", width: "100%", flexWrap: "wrap" }}>
//         {CardComponent}
//         {CardComponent}
//         {CardComponent}
//         {CardComponent}
//     </div>
// );
// }

{/* <div style={{ display: "inline-flex", justifyContent: "space-between", width: "100%", marginTop: "10px" }}>
                    <div>
                        <IonCardTitle style={styles.value}>TS</IonCardTitle>

                        <IonCardSubtitle style={styles.label}>22</IonCardSubtitle>
                    </div>
                    <div>
                        <IonCardTitle style={styles.value}>TS</IonCardTitle>

                        <IonCardSubtitle style={styles.label}>22</IonCardSubtitle>
                    </div>
                    <div>
                        <IonCardTitle style={styles.value}>TS</IonCardTitle>

                        <IonCardSubtitle style={styles.label}>22</IonCardSubtitle>
                    </div>
                    <div>
                        <IonCardTitle style={styles.value}>TS</IonCardTitle>

                        <IonCardSubtitle style={styles.label}>22</IonCardSubtitle>
                    </div>
                    <div>
                        <IonCardTitle style={styles.value}>TS</IonCardTitle>

                        <IonCardSubtitle style={styles.label}>22</IonCardSubtitle>
                    </div>
                </div>
                <div style={{ display: "inline-flex", justifyContent: "space-between", width: "100%", marginTop: "10px" }}>
                    <div>
                        <IonCardTitle style={styles.value}>TS</IonCardTitle>

                        <IonCardSubtitle style={styles.label}>22</IonCardSubtitle>
                    </div>
                    <div>
                        <IonCardTitle style={styles.value}>TS</IonCardTitle>

                        <IonCardSubtitle style={styles.label}>22</IonCardSubtitle>
                    </div>
                    <div>
                        <IonCardTitle style={styles.value}>TS</IonCardTitle>

                        <IonCardSubtitle style={styles.label}>22</IonCardSubtitle>
                    </div>
                    <div>
                        <IonCardTitle style={styles.value}>TS</IonCardTitle>

                        <IonCardSubtitle style={styles.label}>22</IonCardSubtitle>
                    </div>
                    <div>
                        <IonCardTitle style={styles.value}>TS</IonCardTitle>

                        <IonCardSubtitle style={styles.label}>22</IonCardSubtitle>
                    </div>
                </div> */}

