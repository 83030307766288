import { useState } from "react";
import axios from "axios";
import {
  useIonToast,
  IonModal,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
} from "@ionic/react";
import "./back.css";
import ReactApexChart from "react-apexcharts";

export default function Chart({ details, storeCode }) {
  const [present] = useIonToast();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [weekDetails, setWeekDetails] = useState([]);

  const dismiss = () => {
    setOpen(false);
  };

  const weeklyCharts = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/su/week_sale", {
        params: {
          store_code: storeCode,
        },
      });
      const data = response?.data?.data;
      console.log("Weekly:", data);
      setWeekDetails(data);
    } catch (err) {
      if (err.handleGlobally) {
        err.handleGlobally("Error Occurred");
      }
      present({
        message: "Error Occurred",
        duration: 2000,
        color: "danger",
      });
      console.log(err);
    }
    setLoading(false);
  };

  function getFormattedDate(dateString) {
    const dateObj = new Date(dateString);
    const dayName = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][
      dateObj.getDay()
    ];
    const month = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"][
      dateObj.getMonth()
    ]
    const day = dateObj.getDate();
    return `${dayName} - ${day}, ${month}`;
  }

  const series = [
    {
      name: "Day",
      data: weekDetails?.map((x) => x.total_amount),
    },
  ];

  const options = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        dataLabels: {
          orientation: 'vertical',
          position: "top",
          enabled: true,
          style: {
            fontSize: "12px",
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: 10,
      style: {
        fontSize: "14px",
        colors: ["#fff"],
      },
      formatter: function (val) {
        function abbreviateNumber(value) {
          let newValue = value;
          const suffixes = ["", "k", "m", "b", "t"];
          let suffixNum = 0;
          while (newValue >= 1000) {
            newValue /= 1000;
            suffixNum++;
          }
          newValue = newValue.toPrecision(3);
          newValue += suffixes[suffixNum];
          return newValue;
        }

        return abbreviateNumber(val);
      },
    },
    xaxis: {
      categories: weekDetails.map((x) => getFormattedDate(x.bill_date)),
      labels: {
        style: {
          fontSize: "14px",
          colors: "white",
        },
      },
      axisBorder: {
        show: false,
      },
     
    },
    yaxis: {
      title: {
        text: "Amount",
        style: {
          fontSize: "14px",
          color: "white",
        },
      },
      labels: {
        style: {
          fontSize: "14px",
          colors: "white",
        },
        formatter: function (val) {
          function abbreviateNumber(value) {
            let newValue = value;
            const suffixes = ["", "k", "m", "b", "t"];
            let suffixNum = 0;
            while (newValue >= 1000) {
              newValue /= 1000;
              suffixNum++;
            }
            newValue = newValue.toPrecision(3);
            newValue += suffixes[suffixNum];
            return newValue;
          }

          return abbreviateNumber(val);
        },
      },
    },
  };

  return (
    <>
      <button
        className="dropdown"
        onClick={() => {
          setOpen(true);
          weeklyCharts(storeCode);
        }}
      >
     
        <img
          className="vector-icon1"
          alt=""
          src="Graph Icon.png"
          style={{ height: "1.2rem" }}
        />
         <p style={{ fontSize:"0.7rem", paddingTop:"0.3rem", color:'#8cff32'}}>New</p>
      </button>
      <IonModal
        isOpen={open}
        onDidDismiss={() => {
          dismiss();
        }}
        backdropDismiss={true}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>{details?.site_name} </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div style={{ margin: "40px 0px 20px 0px", fill: "" }}>
            <div className="ChartTitle">CURRENT SALE</div>
            <div id="chart">
              <ReactApexChart
                options={options}
                series={series}
                type="bar"
                height={350}
              />
            </div>
          </div>
        </IonContent>
        <ion-footer>
          <p onClick={() => dismiss()} className="back">
            Back
          </p>
        </ion-footer>
      </IonModal>
    </>
  );
}
