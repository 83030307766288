import React, { useState } from 'react';
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonInput, IonButton, IonText, useIonToast } from '@ionic/react';
import axios from 'axios';
// import loginbg from 'loginbg.png';
// import sulogo from 'SU.png';
import "./Page.css"

const LoginPage: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [present] = useIonToast();

  const handleLogin = async () => {
    const values = {
        username,
        password
    }
    // handle login logic here
    console.log(`Username: ${username}, Password: ${password}`);
    await axios.post("/sales/login", {
      // username: "test",
      // password: "test"
      username: values.username,
      password: values.password,
    })
    .then((res) => {
      // console.log(res);
      localStorage.setItem("USERLoginTOKEN", res.data.data.token);
      // localStorage.setItem("RoleToken", res.data.data.role_token);

      localStorage.setItem("username", values.username);
      
      window.location.href = "/";
    })
    .catch((err) => {
        err.handleGlobally && err.handleGlobally("Error Occured");
        console.log(err);
        present({
            message: 'Enter Valid Credentials',
            duration: 1500,
            position: 'bottom',
            color:"danger"
        });
    });
    // present({
    //     message: 'Hello World!',
    //     duration: 1500,
    //     position: 'bottom'
    // });


  };

  return (
    <IonPage >
      {/* <IonHeader>
        <IonToolbar>
          <IonTitle>Login</IonTitle>
        </IonToolbar>
      </IonHeader> */}
      <IonContent class="background">
        <div style={{height:"100%",display:"flex",alignItems:"center", justifyContent:"center" }}>
        {/* <img src="loginbg.png" alt="" style={{position:"absolute"}} /> */}
          <div style={{width:"80%", color:"white",display:"grid",alignItems:"center", justifyContent:"center"}}>
          <img src="SU.png" alt="" style={{}}/>
          <div color="dark" style={{ fontSize:"15px",color:"white", marginBottom:"20px",display:"flex",alignItems:"center", justifyContent:"center"}} >
          Sales App
          </div>
          <div style={{display:"grid",alignItems:"center", justifyContent:"center"}}>
          <IonInput
            type="email"
            placeholder="Email"
            value={username}
            onIonChange={(e) => setUsername(e.detail.value!)}
            className="logininput"
          />
          <IonInput
            type="password"
            placeholder="Password"
            value={password}
            onIonChange={(e) => setPassword(e.detail.value!)}
            className="logininput"
          />
          <IonButton expand="block" onClick={handleLogin} style={{width: "300px"}}>
            Login
          </IonButton>

          <p style={{marginTop:"20px", textAlign:"center", fontSize:"15px"}}>Version {process.env.REACT_APP_VERSION}</p>
          </div>
         
        </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
