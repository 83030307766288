import {
  IonButton,
  IonItem,
  IonList,
  IonPopover,
  IonRadio,
  IonRadioGroup,
  IonRefresher,
  IonRefresherContent,
  IonSkeletonText,
  useIonToast,
} from "@ionic/react";
import axios from "axios";
import React, { useEffect } from "react";
import { CardComponent } from "../components/Cards/StoreCard";
import useInterval from "use-interval";
import { analyticFrequencyInMS, sendAnalytics } from "../components/functions";
import { useSelector } from "react-redux";
import MyPortal from "../components/MyPortal";
import dayjs from "dayjs";
import SortFunctions from "../components/SortFunctions";

const fields = [
  { label: "TS", dataIndex: "today_amt" },
  { label: "YS", dataIndex: "yesterday_amt" },
  { label: "MTD", dataIndex: "this_month_amt" },
  { label: "WTD", dataIndex: "this_week_amt" },
  { label: "OB", dataIndex: "obs_amt" },
  { label: "EB", dataIndex: "ebs_amt" },
  { label: "OBQTY", dataIndex: "obs_qty" },
  { label: "EBQTY", dataIndex: "ebs_qty" },
];

export default function ETHData() {
  const authReducer = useSelector((state) => state.authReducer);
  const [details, setDetails] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [present] = useIonToast();
  const [sortState, setSortState] = React.useState("default");
  const { handleAscSort, handleDesSort } = SortFunctions({
    details,
    setSortState,
    setDetails,
  });
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

  function handleDSort() {
    window.location.reload(true);
  }

  const getData = async (refresh_event) => {
    setLoading(true);
    await axios
      .get("/ethnicity/get_sales_data")
      .then((res) => {
        setDetails(res.data.data);
      })
      .catch((err) => {
        err.handleGlobally && err.handleGlobally("Error Occured");
        present({
          message: "Error Occured",
          duration: 2000,
          color: "danger",
        });
      });
    setLoading(false);
    refresh_event?.detail?.complete();
  };

  useEffect(() => {
    getData();
  }, []);

  useInterval(() => {
    if (document.visibilityState === "visible") {
      sendAnalytics("2", authReducer.user.userId, authReducer.user.username);
    }
  }, analyticFrequencyInMS);
  useEffect(() => {
    sendAnalytics("2", authReducer.user.userId, authReducer.user.username);
  }, []);

  return (
    <div
      style={{
        display: "inline-flex",
        justifyContent: "space-around",
        width: "auto",
        flexWrap: "wrap",
        position: "relative",
        paddingBottom: "50px",
      }}
    >
      <IonRefresher slot="fixed" onIonRefresh={getData}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      {loading && !details?.length && (
        <>
          <IonSkeletonText
            animated={true}
            style={{ width: "min(400px, 90%)", height: "150px" }}
          ></IonSkeletonText>
          <IonSkeletonText
            animated={true}
            style={{ width: "min(400px, 90%)", height: "150px" }}
          ></IonSkeletonText>
          <IonSkeletonText
            animated={true}
            style={{ width: "min(400px, 90%)", height: "150px" }}
          ></IonSkeletonText>
          <IonSkeletonText
            animated={true}
            style={{ width: "min(400px, 90%)", height: "150px" }}
          ></IonSkeletonText>
          <IonSkeletonText
            animated={true}
            style={{ width: "min(400px, 90%)", height: "150px" }}
          ></IonSkeletonText>
          <IonSkeletonText
            animated={true}
            style={{ width: "min(400px, 90%)", height: "150px" }}
          ></IonSkeletonText>
          <IonSkeletonText
            animated={true}
            style={{ width: "min(400px, 90%)", height: "150px" }}
          ></IonSkeletonText>
          <IonSkeletonText
            animated={true}
            style={{ width: "min(400px, 90%)", height: "150px" }}
          ></IonSkeletonText>
          <IonSkeletonText
            animated={true}
            style={{ width: "min(400px, 90%)", height: "150px" }}
          ></IonSkeletonText>
          <IonSkeletonText
            animated={true}
            style={{ width: "min(400px, 90%)", height: "150px" }}
          ></IonSkeletonText>
        </>
      )}
      <MyPortal id="navbar-portal-div">
        <div style={{ fontWeight: "500", width: "auto", display: "grid" }}>
          <span>Ethnicity</span>
          <span style={{ fontSize: "0.8rem", marginTop: "0.2rem" }}>
            {details?.[1]?.updated_at &&
              dayjs(details?.[1]?.updated_at).fromNow()}
          </span>
        </div>
        <div>
          <IonButton
            size="small"
            color="white"
            id="popover-eth-button"
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          >
            Sort By
          </IonButton>
        </div>
      </MyPortal>
      {details.map((item, index) => {
        return (
          <CardComponent
            key={index}
            data={item}
            fields={fields}
            fieldsInOneRow={4}
          />
        );
      })}

      <div>
        <IonPopover
          isOpen={isPopoverOpen}
          trigger="popover-eth-button"
          keepContentsMounted={true}
          dismissOnSelect={true}
          side="bottom"
          size="auto"
        >
          <IonList>
            <IonRadioGroup>
              <IonItem onClick={() => handleDSort("default")}>Default</IonItem>
              <IonItem onClick={() => handleAscSort("today_amt")}>
                <b>Sales</b>&nbsp;| Low to High
                <IonRadio slot="end"></IonRadio>
              </IonItem>
              <IonItem onClick={() => handleDesSort("today_amt")}>
                <b>Sales</b> &nbsp;| High to Low
                <IonRadio slot="end"></IonRadio>
              </IonItem>
            </IonRadioGroup>
          </IonList>
        </IonPopover>
      </div>
    </div>
  );
}
