import {
    IonContent,
    IonFooter,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonToggle,
    IonButton, 
    IonTabButton,
    IonBadge
} from "@ionic/react";

import { useLocation } from "react-router-dom";
import {
    archiveOutline,
    archiveSharp,
    bookmarkOutline,
    heartOutline,
    heartSharp,
    mailOutline,
    mailSharp,
    paperPlaneOutline,
    paperPlaneSharp,
    trashOutline,
    trashSharp,
    warningOutline,
    warningSharp,
} from "ionicons/icons";
import "./Menu.css";
import { useSelector , useDispatch } from "react-redux";
import { home, settings, moon } from "ionicons/icons";
import { useEffect } from "react";
import {ar_logoutUser} from "../Redux/Actions/AuthActions"


interface AppPage {
    url: string;
    iosIcon: string;
    mdIcon: string;
    title: string;
}

const appPages: AppPage[] = [
    // {
    //     // title: "All Stores",
    //     // url: "/stores",
    //     // iosIcon: mailOutline,
    //     // mdIcon: mailSharp,
    // },
];

const Menu: React.FC = () => {
    const location = useLocation();
    const authReducer = useSelector((state: any) => state.authReducer);
    const dispatch = useDispatch();
    // const toggleDarkModeHandler = () => {
	// 	document.body.classList.toggle("dark");
	// 	if (document.body.classList.contains("dark")) {
	// 		localStorage.setItem("darkMode", "true");
	// 	}
	// 	else {
	// 		localStorage.setItem("darkMode", "false");
	// 	}
	// }

	useEffect(() => {
		// if (localStorage.getItem("darkMode") === "true") {
			document.body.classList.add("dark");
		// }
	}, []);
function logutUser(){
    dispatch(ar_logoutUser());
}

// let navigate = useNavigate();

    return (
        <IonMenu contentId="main" type="overlay">
            <IonContent>
                <IonList id="inbox-list">
                    <IonListHeader >
                        <div style={{display: 'inline-flex', width: '100%', justifyContent: 'space-between', alignItems: 'flex-start'}}>
                            <span>{authReducer.user.username}</span>
                            {/* <IonBadge style={{marginLeft: '20px'}} color="success">BETA</IonBadge> */}
                        </div>
                    </IonListHeader>
                    <IonNote>{authReducer.user.email}</IonNote>
                    
                    {appPages.map((appPage, index) => {
                        return (
                            <IonMenuToggle key={index} autoHide={false}>
                                <IonItem
                                    className={location.pathname === appPage.url ? "selected" : ""}
                                    routerLink={appPage.url}
                                    routerDirection="none"
                                    lines="none"
                                    detail={false}
                                >
                                    <IonIcon aria-hidden="true" slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                                    <IonLabel>{appPage.title}</IonLabel>
                                    
                                </IonItem>
                                
                            </IonMenuToggle>
                        );
                    })}
                </IonList>
                <br></br>
                    {/* <span style={{marginLeft:"0.5rem", color:"aqua"}}>BETA VERSION</span> */}
                {/* <IonFooter>
					<IonList>
                       
						<IonItem lines="none">
							<IonIcon slot="start" icon={moon} />
							<IonLabel>Dark Mode</IonLabel>
							<IonToggle slot="end" name="darkMode" 
								checked={localStorage.getItem("darkMode") === "true"}
							onIonChange={toggleDarkModeHandler} />
						</IonItem>
					</IonList>
				</IonFooter> */}
                <IonButton href="/su-pred"  fill="outline" style={{width:"95%"}}>SU Sales Prediction</IonButton>
                <IonButton href="/sales/styleunion" fill="outline" style={{width:"95%"}}>All Store Sales</IonButton>

                
            </IonContent>
            <IonButton fill="outline"  onClick={logutUser} style={{width:"95%",background: '#000000'}}>Logout</IonButton>
        </IonMenu>
    );
};

export default Menu;
