const SortFunctions = ({ details, setSortState, setDetails }) => {

  const handleAscSort = (sortProperty) => {

    const sortedAsc = [...details.slice(1)].sort((a, b) => {
      return a[sortProperty] - b[sortProperty];
    });

    const updatedAsc = [details[0], ...sortedAsc];
    setSortState(updatedAsc);
    setDetails(updatedAsc);
  };

  const handleDesSort = (sortProperty) => {
    
    const sortedDes = [...details.slice(1)].sort((a, b) => {
      return b[sortProperty] - a[sortProperty];
    });

    const updatedDes = [details[0], ...sortedDes];
    setSortState(updatedDes);
    setDetails(updatedDes);
  };

  return {
    handleAscSort,
    handleDesSort,
  };
};

export default SortFunctions;
