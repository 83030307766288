import React from 'react'
import StoreCard1 from '../components/Cards/StoreCard1'

export default function SUPred() {
  return (
    <div>
        <StoreCard1/>
    </div>
  )
}
